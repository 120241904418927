import { Button } from '@chakra-ui/react'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
  DOPPEL_BUTTON_GREY,
  DOPPEL_OFFICIAL_BLUE,
} from '../../utils/style'

const DoppelPrimaryButton = ({
  children,
  isInternal = false,
  isPrimaryColor = false,
  ...props
}) => {
  const bgColor = (() => {
    if (isInternal) return DOPPEL_INTERNAL_PURPLE
    else if (isPrimaryColor) return DOPPEL_OFFICIAL_BLUE
    return DOPPEL_BUTTON_GREY
  })()
  return (
    <Button
      as={Button}
      bgColor={bgColor}
      fontSize="16"
      minWidth="80px"
      textColor={DOPPEL_WHITE}
      {...props}
    >
      {children}
    </Button>
  )
}

export default DoppelPrimaryButton
